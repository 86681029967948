import React, { useRef, useState, useEffect } from "react";
import "./App.css";

import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

const emojis = require("emojis-list");

firebase.initializeApp({
  apiKey: "AIzaSyBi5H_hjXCmbCsEVhJsyjmHLxt1z6M4Kt0",
  authDomain: "shortlived.werzq.cc",
  databaseURL:
    "https://shortlived-inc-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "shortlived-inc",
  storageBucket: "shortlived-inc.appspot.com",
  messagingSenderId: "91806687211",
  appId: "1:91806687211:web:675c2faf981a1ff0ff43ae",
  measurementId: "G-7TDXEJXVD0",
});

const auth = firebase.auth();
const database = firebase.database();

const isDesktop = () => {
  const userAgent = window.navigator.userAgent;
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  return !isMobile;
};

function App() {
  const [user] = useAuthState(auth);
  const [isSupportedBrowser, setIsSupportedBrowser] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isDesktop()) {
      setIsSupportedBrowser(false);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {isSupportedBrowser ? (
        <>
          <header>
            <h1>
              Short<span className="brand-dim">Lived</span>
            </h1>
            <SignOut />
          </header>
          <section>{user ? <ChatRoom /> : <SignIn />}</section>
        </>
      ) : (
        <>
          <header>
            <h1>
              Short<span className="brand-dim">Lived</span>
            </h1>
          </header>
          <section>
            <div className="unsupported-message">
              <p>
                Apologies, but currently, this application is not adequately
                supported for mobile browsers.
              </p>
              <p>
                Mobile support coming soon&trade;
              </p>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return (
    <>
      <div className="centered">
        <button className="login-with-google-btn" onClick={signInWithGoogle}>
          Sign in with Google
        </button>
      </div>
      <div className="centered">
        <p className="justify intro">
          Imagine a chat service with just one big chatroom, where all messages
          disappear after 12 hours. Everything's here today, gone tomorrow.
          Share whatever you want, but remember, it's only gonna be there for 12
          hours. All of this while remaining anonymous.
        </p>
      </div>
      <div className="centered mask">We support Palestine {emojis[220]}</div>
    </>
  );
}

function SignOut() {
  return (
    auth.currentUser && (
      <button className="sign-out" onClick={() => auth.signOut()}>
        <div className="sign-out-button">
          <FontAwesomeIcon icon={faRightFromBracket} />
        </div>
      </button>
    )
  );
}

function groupMessages(messages) {
  const messageGroups = [];
  let currentGroup =
    messages.length > 0 && messages[0].uid === auth.currentUser.uid
      ? "sender"
      : "receiver";

  let currentGroupMessages = [];
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].uid === auth.currentUser.uid && currentGroup === "sender") {
      currentGroupMessages.push(messages[i]);
    } else if (
      messages[i].uid !== auth.currentUser.uid &&
      currentGroup === "receiver"
    ) {
      currentGroupMessages.push(messages[i]);
    } else {
      messageGroups.push({
        group: currentGroup,
        messages: currentGroupMessages,
      });
      currentGroup =
        messages[i].uid === auth.currentUser.uid ? "sender" : "receiver";
      currentGroupMessages = [messages[i]];
    }
  }

  if (currentGroupMessages.length > 0) {
    messageGroups.push({ group: currentGroup, messages: currentGroupMessages });
  }

  return messageGroups;
}

function ChatRoom() {
  const dummy = useRef();
  const messagesRef = useRef(database.ref("messages"));

  const [messages, setMessages] = useState([]);
  const [formValue, setFormValue] = useState(""); // Define formValue state

  useEffect(() => {
    const messagesRefValue = messagesRef.current;

    const fetchData = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messagesArray = Object.entries(data).map(([id, message]) => {
          return { id, text: message.text, uid: message.uid };
        });
        setMessages(messagesArray);
      }
    };

    messagesRefValue.on("value", fetchData);

    return () => {
      messagesRefValue.off("value", fetchData);
    };
  }, []);

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = (e) => {
    e.preventDefault();
    const { uid } = auth.currentUser;
    const text = formValue;

    messagesRef.current.push({
      text,
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      uid,
    });

    setFormValue("");
  };

  return (
    <>
      <main>
        {groupMessages(messages).map((group, index) => (
          <div key={index} className={group.group}>
            {group.messages.map((msg, msgIndex) => (
              <ChatMessage
                key={msgIndex}
                message={msg}
                isFirst={msgIndex === 0}
                isLast={msgIndex === group.messages.length - 1}
              />
            ))}
          </div>
        ))}
        <span ref={dummy}></span>
      </main>

      <form onSubmit={sendMessage}>
        <input
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Message..."
        />
        <button type="submit" disabled={!formValue}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </form>
    </>
  );
}

function ChatMessage({ message, isFirst, isLast, previousMessage }) {
  const { text, uid } = message;
  const isSent = uid === auth.currentUser.uid;
  const isReceived = !isSent;

  const messageClass = isSent ? "sent" : "received";

  const isSameSender = previousMessage && previousMessage.uid === uid;

  return (
    <div className={`message ${messageClass}`}>
      {isReceived && (isFirst || !isSameSender) && (
        <div className="profile-img">
          <img src={"./profile.svg"} alt="pfp" />
        </div>
      )}
      <div className="text">{text}</div>
    </div>
  );
}

export default App;
